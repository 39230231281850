import React from 'react';

class Modal extends React.Component {
  render() {
    return (
      <div className="iframe-wrap m-b-20" style={{ maxHeight: '500px', overflowY: 'scroll' }}>
        As you may already know, biologic markers (biomarkers) are being used to screen, detect and diagnose many individuals, help in prognostic evaluation, monitor treatment response, and predict disease recurrence.  We are offering our service called SeroMark-1 test for detecting the presence or absence of prostate cancer, that may direct the conversation toward personalized medicine and targeted therapies, or toward other testing and treatment modalities appropriate for you.
        <br /><br />By participating in the SeroMark-1 testing opportunity, you recognize and accept all risks associated with the screening process.  You understand that you may still need to conduct DRE (digital rectal exam), PSA (prostate specific antigen) test or any other relevant tests and that blood draw for Seromark-1 analysis does not constitute a complete medical exam or diagnosis for prostate cancer.
        <br /><br />You hereby release NUTECH CANCER BIOMARKERS INDIA PRIVATE LIMITED, and any other organization involved with either NUTECH CANCER BIOMARKERS INDIA PRIVATE LIMITED or their agents, from all liabilities, medical claims or expenses which may arise from your participation or injury sustained during this testing.
        <br /><br />
        <strong>How much personal and medical information should I share?</strong>
        <br />You have a choice as to how much or how little information you want to share. For example, you can consent just to having your blood collected and tested. Or you can consent to sharing personal information such as your age, current medications, racial or ethnic group, and history of smoking. You can also allow access to your medical record. The choice is yours.  Knowing this information may help researchers understand how these factors affect the risk of prostate cancer. And being able to study patients’ blood/tissues in light of their medical histories may tell scientists a lot more about the causes of cancer and how to develop new therapies
        <br /><br />
        <strong>How will my privacy be protected?</strong>
        <br />Indian government laws and regulations protect the privacy and confidentiality of your medical information. We may consult a committee at a hospital, known as the Institutional Review Board, or IRB, which will review the plan to make sure that it strictly follows these laws and regulations.
        <br />Several procedures have been put into place to protect the privacy of your medical record information.  For example, direct identifiers (such as your name, Aadhaar Card #, PAN #, telephone number, address) will be encoded as ID# and removed from the information before it is sent for analysis or if provided to the researchers.  Only honest broker or your physician will have access to your identifiable medical record information, and these individuals will be required to sign a privacy agreement.  However, just as with the use of your medical information for health care purposes, we cannot guarantee its privacy.
        <br /><br />
        <strong>What are the financial implications to SeroMark-1 analysis?</strong>
        <br />There is either a waiver or an upfront charge of Rs. 4000/test to you, or to your insurance company. At the same time, you will not be paid any fee for your blood or for any of the discoveries that might result from analysis/research using your blood
        <br /><br />
        <strong>What if I change my mind after giving blood or blood sample?</strong>
        <br />You can change your mind at any time about allowing your blood to be used in future research. The consent form explains how to contact the company. If you wish to withdraw your consent for all research or change the amount of personal information you are willing to share, tell the administrator at NUTECH CANCER BIOMARKERS INDIA PRIVATE LIMITED immediately. However, if some of your blood has already been sent out, and/or the company cannot get it back, you cannot withdraw your permission for the research done on those particular blood samples
        <br /><br />
        <strong>Will I get the results of SeroMark-1 test?</strong>
        <br />Yes. The company can also directly communicate, in total confidentiality, the results of your blood analysis with your doctor/physician.  Other researchers will not report to you the results of any research using your donated blood. Research studies take many years and require blood samples from hundreds or thousands of people. The results are published in scientific journals or in media.
        <br /><br />
        <strong>Will SeroMark-1 test results be shared with my employer or health insurance provider?</strong>
        <br />Results of your blood analysis will not be put in your medical record, and will not be released to your employer, health insurance provider, or anyone else
        <br /><br />
        <strong>Will SeroMark-1 test using my blood contribute to my medical care?</strong>
        <br />The results of blood analysis will not affect your care right now. If your cancer comes back in the future, however, new treatment options may be available
        <br /><br />
        <strong>How do I decide whether or not to give blood?</strong>
        <br />You decide; since you or your Insurance is paying for the analysis.  Feel free to talk it over with your doctor or nurse if you are unsure
        <br /><br />
        <strong><u />Consents<u /></strong>
        <ul>
          <li>I agree to allow my blood, and other bodily tissues/fluids for SeroMark-1 India test and to be used for research.</li>
          <li>I agree to allow SeroMark-1 and PSA analysis data; and if I chose to have a biopsy or biopsies then details of all of my biopsy results, be shared with NUTECH CANCER BIOMARKERS INDIA PRIVATE LIMITED and/or their assigns.  This information will be used in research to learn and educate about, prevent, treat, or cure diseases and other health</li>
          <li>I <strong>{this.props.fullName}</strong> , have read this form and understand its contents and that NUTECH CANCER BIOMARKERS INDIA PRIVATE LIMITED may maintain a copy of my results to be used for future statistical evaluation and scientific literature.  However, I will not be individually identified in any recognizable way.  If I do not list a physician, a copy of the results of SeroMark-1 analyses will be sent directly to me and the physician performing my exam later upon request.  I also authorize NUTECH CANCER BIOMARKERS INDIA PRIVATE LIMITED or their designated staff contact me at a later date regarding this participation if they so desire</li>
        </ul>
        <br /><br />
        <h4>WAIVER AND RELEASE OF LIABILITY</h4>
        <br />In consideration of the risk of injury while participating in SeroMark-1 test (the "Activity"), and as consideration for the right to participate in the Activity, I hereby, for myself, my heirs, executors, administrators, assigns, or personal representatives, knowingly and voluntarily enter into this waiver and release of liability and hereby waive any and all rights, claims or causes of action of any kind whatsoever arising out of my participation in the Activity, and do hereby release and forever discharge NUTECH CANCER BIOMARKERS INDIA PRIVATE LIMITED, located in INDIA, their affiliates, managers, members, agents, attorneys, staff, volunteers, heirs, representatives, predecessors, successors and assigns, for any physical or psychological injury, including but not limited to illness, paralysis, death, damages, economical or emotional loss, that I may suffer as a direct result of my participation in the aforementioned Activity, including traveling to and from an event related to this Activity.
        <br /><br />I am voluntarily participating in the aforementioned Activity and I am participating in the Activity entirely at my own risk. I am aware of the risks associated with traveling to and from as well as participating in this Activity, which may include, but are not limited to, physical or psychological injury, pain, suffering, illness, disfigurement, temporary or permanent disability (including paralysis), economic or emotional loss, and death. I understand that these injuries or outcomes may arise from my own or others' negligence, conditions related to travel, or the condition of the Activity location(s). Nonetheless, I assume all related risks, both known or unknown to me, of my participation in this Activity, including travel to, from and during this Activity.
        <br /><br />I agree to indemnify and hold harmless NUTECH CANCER BIOMARKERS INDIA PRIVATE LIMITED against any and all claims, suits or actions of any kind whatsoever for liability, damages, compensation or otherwise brought by me or anyone on my behalf, including attorney's fees and any related costs, if litigation arises pursuant to any claims made by me or by anyone else acting on my behalf. If NUTECH CANCER BIOMARKERS INDIA PRIVATE LIMITED incurs any of these types of expenses, I agree to reimburse NUTECH CANCER BIOMARKERS INDIA PRIVATE LIMITED.
        <br /><br />I acknowledge that NUTECH CANCER BIOMARKERS INDIA PRIVATE LIMITED and their directors, employees, officers, volunteers, representatives and agents are not responsible for errors, omissions, acts or failures to act of any party or entity conducting a specific event or activity on behalf of NUTECH CANCER BIOMARKERS INDIA PRIVATE LIMITED.
        <br /><br />I acknowledge that this Activity may also involve a test of a person's physical and mental limits and may carry with it the potential for death, serious injury, and property loss. The risks may include, but are not limited to, those caused by terrain, facilities, temperature, weather, lack of hydration, condition of participants, equipment, vehicular traffic and actions of others, including but not limited to, participants, volunteers, spectators, coaches, event officials and event monitors, and/or producers of the event.
        <br /><br />I acknowledge that I have carefully read this "waiver and release" and fully understand that it is a release of liability. I expressly agree to release and discharge NUTECH CANCER BIOMARKERS INDIA PRIVATE LIMITED and all of its affiliates, managers, members, agents, attorneys, staff, volunteers, heirs, representatives, predecessors, successors and assigns, from any and all claims or causes of action and I agree to voluntarily give up or waive any right that I otherwise have to bring a legal action against NUTECH CANCER BIOMARKERS INDIA PRIVATE LIMITED for personal injury or property damage.
        <br /><br />To the extent that statute or case law does not prohibit releases for negligence, this release is also for negligence on the part of NUTECH CANCER BIOMARKERS INDIA PRIVATE LIMITED, its agents, and employees.
        <br /><br />In the event that I should require medical care or treatment, I agree to be financially responsible for any costs incurred as a result of such treatment. I am aware and understand that I should carry my own health insurance.
        <br /><br />In the event that any damage to equipment or facilities occurs as a result of my or my family's willful actions, neglect or recklessness, I acknowledge and agree to be held liable for any and all costs associated with any actions of neglect or recklessness.
        <br /><br />This Agreement was entered into at arm's-length, without duress or coercion, and is to be interpreted as an agreement between two parties of equal bargaining strength. Both the Participant, <strong>{this.props.fullName}</strong>, and NUTECH CANCER BIOMARKERS INDIA PRIVATE LIMITED agree that this Agreement is clear and unambiguous as to its terms, and that no other evidence will be used or admitted to alter or explain the terms of this Agreement, but that it will be interpreted based on the language in accordance with the purposes for which it is entered into.
        <br /><br />In the event that any provision contained within this Release of Liability shall be deemed to be severable or invalid, or if any term, condition, phrase or portion of this agreement shall be determined to be unlawful or otherwise unenforceable, the remainder of this agreement shall remain in full force and effect, so long as the clause severed does not affect the intent of the parties. If a court should find that any provision of this agreement to be invalid or unenforceable, but that by limiting said provision it would become valid and enforceable, then said provision shall be deemed to be written, construed and enforced as so limited
        <br /><br />I, the undersigned participant, affirm that I am of the age of 18 years or older, and that I am freely signing this agreement. I certify that I have read this agreement, that I fully understand its content and that this release cannot be modified orally. I am aware that this is a release of liability and a contract and that I am signing it of my own free will.
        <br /><br /><br />
        <strong>{this.props.fullName},</strong>
        <br /><strong>{this.props.area},</strong>
        <br /><strong>{this.props.city},</strong>
        <br /><strong>{this.props.istate} - {this.props.pincode}.</strong>
        <br /><strong>{new Date().toString()}</strong>
      </div>
    )
  }
}

export default Modal;