import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import Nav from "./Nav";
import FooterComponent from "./FooterComponent";

class Difference extends React.Component {
  componentDidMount() {
    window.updateUIAfterReact();
  }
  render() {
    return (
      <div>
        <Nav />
        <div
          className="breadcrumbs"
       >
          {/* Slide 2 */}
          <div className="slide">
            <div className="container">
              <div className="slide-captions text-left text-light">
                {/* Captions */}
                 <h1>Early Detection & Advance Treatment Can Save Lives...</h1>
                {/* end: Captions */}
              </div>
            </div>
          </div>
          {/* end: Slide 2 */}
        </div>
<section className="parallax background-overlay-dark"
        >

                   <div className="container">
            <h2 style={{textAlign:'center', textTransform:'none'}}>Differences between Diagnostic Tests and Screening Tests</h2>
          <table className="table-responsive">
<tbody   className="pcolor">
<tr>
<td><strong> </strong></td>
<td><strong>Diagnostic test</strong></td>
<td><strong>Screening test</strong></td>
</tr>
<tr>
<td><strong>Specificity and Sensitivity</strong></td>
<td>The cut-off is set towards high specificity, with more weight given to diagnostic precision and accuracy than to the acceptability of the test to patients</td>
<td>The cut-off is set towards high sensitivity. As a result, many of the positive results are false positives. This is acceptable, particularly if the screening test is not harmful or expensive.</td>
</tr>
<tr>
<td><strong>Cost</strong></td>
<td>Patients have symptoms that require accurate diagnosis and therefore higher costs are justified.</td>
<td>Since large numbers of people will be screened to identify a very small number of cases, the financial resources needed must be justified carefully.</td>
</tr>
<tr>
<td><strong>Result of the test</strong></td>
<td>The test result provides a definitive diagnosis (e.g. a definite diagnosis of Meningitis through blood test or lumbar puncture.)</td>
<td>The result of the test is an estimate of the level of risk and determines whether a diagnostic test is justified.</td>
</tr>
<tr>
<td><strong>Invasiveness</strong></td>
<td>May be invasive (involving the introduction of instruments or other objects into the body or body cavities).</td>
<td>Often non-invasive.</td>
</tr>
<tr>
<td><strong>Population offered the test</strong></td>
<td>Those with symptoms or who are under investigation following a positive screening test.</td>
<td>Those without symptoms of disease, but can be at risk.</td>
</tr>
</tbody>
          </table>
          </div>
        </section>
        {/* Footer */}
        <FooterComponent />
        {/* end: Footer */}
        {/* end: Wrapper */}
        {/* Go to top button */}
        <a href="#home" id="goToTop">
          <i className="fa fa-angle-up top-icon" />
          <i className="fa fa-angle-up" />
        </a>
      </div>
    );
  }
}

export default Difference;
