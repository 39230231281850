import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import Nav from "../components/Nav";
import FooterComponent from "./FooterComponent";

class Publications extends React.Component {
  componentDidMount() {
    window.updateUIAfterReact();
  }
  render() {
    return (
      <div>
        <Nav />
        <div
          className="breadcrumbs">
          {/* Slide 2 */}
          <div className="slide">
            <div className="container">
              <div className="slide-captions text-left text-light">
                {/* Captions */}
                <h1>Fight Prostate Cancer... With New Hope!
 </h1>
                {/* end: Captions */}
              </div>
            </div>
          </div>
          {/* end: Slide 2 */}
        </div>
        <section>
          <div className="container">
            <div className="col-md-12">
<div className="section-title text-center m-b-20">
              <h2>Publications</h2>
            </div>

              <div className="row">
                    <ul className="no">
<li>
 <a href="images/publication/PLoS-Article.pdf" target="_blank">
                  1.  A Novel Three Serum Phospholipid Panel Differentiates
Normal Individuals from Those with Prostate Cancer

                      </a>

</li>
<li>
 <a href="/downloads/clinic-broucher.pdf" target="_blank">
                2. Clinician's Brochure
                      </a>

</li>
<li>
 <a href="/downloads/ask-your-doctor_r3.pdf" target="_blank">
                3.  Ask your Doctor (Patient's Leaflet) 
                      </a>

</li>
<li>
 <a href="/downloads/patient-broucher.pdf" target="_blank">
                4.  Patient's  Brochure 
                      </a>

</li>

</ul>
               
              </div>
          </div>
          </div>
</section>
        {/* Footer */}
        <FooterComponent />
        {/* end: Footer */}
        {/* end: Wrapper */}
        {/* Go to top button */}
        <a href="#home" id="goToTop">
          <i className="fa fa-angle-up top-icon" />
          <i className="fa fa-angle-up" />
        </a>
      </div>
    );
  }
}

export default Publications;
