import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import Nav from "../components/Nav";
import FooterComponent from "./FooterComponent";

class NewsComponent extends React.Component {
  componentDidMount() {
    window.updateUIAfterReact();
  }
  render() {
    return (
      <div>
        <Nav />
        <div
          className="breadcrumbs"
>
          {/* Slide 2 */}
          <div className="slide">
            <div className="container">
              <div className="slide-captions text-left text-light">
                {/* Captions */}
                <h1>Fight Prostate Cancer... With New Hope!</h1>
              </div>
            </div>
          </div>
          {/* end: Slide 2 */}
        </div>

       {/* Our News */}
        <section className="background-grey">
          <div className="container">


 <div className="col-md-12  m-b-20">
               <div className="row" style={{margin:"0px",}}>  <div className="section-title text-left m-b-20">
              <h2>Blog</h2>
            </div>
<h3>  <a href="/blog" target="_blank">
                     Let’s Shake off Complacency and Create a Sense of Urgency

                      </a>
</h3>

</div></div>

            <div className="section-title m-b-20">
              <h2>Know More...</h2>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-3">
                  <div className="news">
                     <a href="https://www.youtube.com/watch?v=ZUSkWb5QP8A&feature=share&ab_channel=ProstateCancerResearchInstitute" target="_blank">
<img
                      src="images/news/blog1.png"
                      className="img-responsive"
                    ></img></a>
                  
                    <h5>
                      <a href="https://www.youtube.com/watch?v=ZUSkWb5QP8A&feature=share&ab_channel=ProstateCancerResearchInstitute" target="_blank">
                      What is a normal PSA finding ? 
                      </a>
                    </h5>
                  </div>
                </div>
                               <div className="col-md-3">
                  <div className="news">
                    <a href="https://www.youtube.com/watch?v=RitPsVQPlz8&feature=share&ab_channel=CityNewsToronto" target="_blank"><img
                      src="images/news/blog2.png"
                      className="img-responsive"
                    ></img></a>
                    
                    <h5>
                      <a href="https://www.youtube.com/watch?v=RitPsVQPlz8&feature=share&ab_channel=CityNewsToronto" target="_blank">
                       ‘Cheeky’ prostate cancer ad to raise awareness
                      </a>
                    </h5>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="news">
                     <a href="https://www.youtube.com/watch?v=zg3j5Ig4dJY&feature=share&ab_channel=Johnson%26Johnson" target="_blank">
 <img
                      src="images/news/blog3.png"
                      className="img-responsive"
                    ></img></a>
                    
                    <h5>
                      <a href="https://www.youtube.com/watch?v=zg3j5Ig4dJY&feature=share&ab_channel=Johnson%26Johnson" target="_blank">
                      About Prostate <br></br>{" "}Cancer

                      </a>
                    </h5>
                  </div>
                </div>
<div className="col-md-3">
                  <div className="news">
                   <a href="https://www.youtube.com/watch?v=6O6R6HciUBU&feature=share" target="_blank">
 <img
                      src="images/news/blog4.png"
                      className="img-responsive"
                    ></img></a>
                    <h5>
                      <a href="https://www.youtube.com/watch?v=6O6R6HciUBU&feature=share" target="_blank">
                       Prostate Cancer | Dr. P N Dogra 

                      </a>
                    </h5>
                  </div>
                </div>
               
              </div>
            </div>

            <div className="col-md-12 p-t-40">
              <div className="row">
                
 <div className="col-md-3">
                  {" "}
                  <div className="news">
                   <a href="https://www.youtube.com/watch?v=yDj0pXTC2fc&feature=share&ab_channel=ManipalHospitals" target="_blank">
  <img
                      src="images/news/blog5.png"
                      className="img-responsive"
                    ></img></a>
                    <h5>
                      <a href="https://www.youtube.com/watch?v=yDj0pXTC2fc&feature=share&ab_channel=ManipalHospitals" target="_blank">
                        Dr. Uday Bhaskar | Prostate Cancer | Manipal Hospitals India

                      </a>
                    </h5>
                  </div>
                </div>


                <div className="col-md-3">
                  <div className="news">
                     <a href="https://www.youtube.com/watch?v=2TYOAGt0qac&ab_channel=DharamshilaNarayanaSuperspecialityHospital" target="_blank">
<img
                      src="images/news/blog6.png"
                      className="img-responsive"
                    ></img></a>
                    <h5>
                      <a href="https://www.youtube.com/watch?v=2TYOAGt0qac&ab_channel=DharamshilaNarayanaSuperspecialityHospital" target="_blank">
                      Prostate Cancer (Hindi)| Dr. Anshuman Kumar | Dharamshila Hospital

                      </a>
                    </h5>
                  </div>
                </div>
  <div className="col-md-3">
                  <div className="news">
                   <a href="https://youtu.be/i1GcovxDnrM" target="_blank">
  <img
                      src="images/news/blog7.png"
                      className="img-responsive"
                    ></img></a>
                    <h5>
                      <a href="https://youtu.be/i1GcovxDnrM" target="_blank">
                      Prostate Cancer (Hindi)| Dr. Anshuman Kumar | Dharamshila Hospital

                      </a>
                    </h5>
                  </div>
                </div>
                
                <div className="col-md-3">
                  {" "}
                  <div className="news">
                   <a href="https://www.youtube.com/watch?v=ttO_kfy498c&feature=share" target="_blank">
 <img
                      src="images/news/blog8.png"
                      className="img-responsive"
                    ></img></a>
                    <h5>
                      <a href="https://www.youtube.com/watch?v=ttO_kfy498c&feature=share" target="_blank">
                     PCa awareness | Dr. Manisha Singh | Mahavir Cancer Institute 

                      </a>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
   <div className="col-md-12 p-t-40">
              <div className="row">
                <div className="col-md-3">
                  <div className="news">
                   <a href="https://www.youtube.com/watch?v=qzOF71Zuonw&feature=share" target="_blank">
 <img
                      src="images/news/blog9.png"
                      className="img-responsive"
                    ></img></a>
                    <h5>
                      <a href="https://www.youtube.com/watch?v=qzOF71Zuonw&feature=share" target="_blank">
                     प्रोस्टेट कैंसर से कैसे  <br></br>{" "} बचें ।

                      </a>
                    </h5>
                  </div>
                </div>
              

<div className="col-md-3">
                  <div className="news">
                     <a href="https://www.youtube.com/watch?v=LveW9-YtXYo&feature=share" target="_blank">
 <img
                      src="images/news/blog10.png"
                      className="img-responsive"
                    ></img></a>
                   
                    <h5>
                      <a href="https://www.youtube.com/watch?v=LveW9-YtXYo&feature=share" target="_blank">
                     Information an cancer (in Hindi)
                      </a>
                    </h5>
                  </div>
                </div>

<div className="col-md-3">
                  <div className="news">
                     <a href="https://www.youtube.com/watch?v=2LnvcMlc5EE&feature=youtu.be&ab_channel=MedSchoolMadeEasy" target="_blank">
 <img
                      src="images/news/blog11.png"
                      className="img-responsive"
                    ></img></a>
                   
                    <h5>
                      <a href="https://www.youtube.com/watch?v=2LnvcMlc5EE&feature=youtu.be&ab_channel=MedSchoolMadeEasy" target="_blank">
True Positive vs. True Negative vs. False Positive vs. False Negative
                      </a>
                    </h5>
                  </div>
                </div>
<div className="col-md-3">
                  <div className="news">
                     <a href="https://www.youtube.com/watch?v=Z5TtopYX1Gc&feature=youtu.be&ab_channel=MedCram-MedicalLecturesExplainedCLEARLY" target="_blank">
 <img
                      src="images/news/blog12.png"
                      className="img-responsive"
                    ></img></a>
                   
                    <h5>
                      <a href="https://www.youtube.com/watch?v=Z5TtopYX1Gc&feature=youtu.be&ab_channel=MedCram-MedicalLecturesExplainedCLEARLY" target="_blank">
Sensitivity and Specificity Explained Clearly (Biostatistics)

                      </a>
                    </h5>
                  </div>
                </div>


              
              </div>
            </div>

          </div>
        </section>
        {/* end: Our News */}

        {/* Footer */}
        <FooterComponent />
        {/* end: Footer */}
        {/* end: Wrapper */}
        {/* Go to top button */}
        <a href="#home" id="goToTop">
          <i className="fa fa-angle-up top-icon" />
          <i className="fa fa-angle-up" />
        </a>
      </div>
    );
  }
}

export default NewsComponent;
