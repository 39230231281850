import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import Nav from "../components/Nav";
import FooterComponent from "./FooterComponent";

class seromarktest extends React.Component {
  componentDidMount() {
    window.updateUIAfterReact();
  }
  render() {
    return (
      <div>
        <Nav />
        <div className="breadcrumbs">
          {/* Slide 2 */}
          <div className="slide">
            <div className="container">
              <div className="slide-captions text-left text-light">
                {/* Captions */}
                <h1>Early Detection & Advance Treatment Can Save Lives...</h1>
                {/* end: Captions */}
              </div>
            </div>
          </div>
          {/* end: Slide 2 */}
        </div>
 <section className="parallax background-overlay-dark"
        >
          <div className="container">
            <div className="row">
              <div className="col-sm-6">
                <div className="slide-captions text-left">
                  <h2>
                    {" "}
                   SeroMark-1 Test
                  </h2>
                  <p className="pcolor">
                   The SeroMark-1 Prostate Test, is a simple, reasonably priced and the only screening test for early detection of prostate cancer in "normal appearing" men. SeroMark-1 test result provides actionable information, that aids treatment decision for both; caregivers as well as men aged 40 and above. </p>

 <p className="pcolor">
SeroMark-1 test is a patented technology (<a href="https://www.freepatentsonline.com/9903878.pdf" target="new" style={{color:"blue"}}>See Patent</a>), that examines concentration of specific phospholipids in human blood (serum). Phospholipids are a subclass of a large and diverse group of organic compounds called lipids, which are building blocks of cellular membranes.</p>
 <p className="pcolor">
   <b>Clinical Utility and Validation of Data:</b> The SeroMark-1 Prostate Test was studied in a real-world clinical setting that included expert urologists, and more than 1,000 patients from India. The study is the first-ever retrospective, randomized prostate biomarker trial with a blinded control arm conducted in a clinical utility setting.</p>

                </div>
              </div>
              <div className="col-sm-6">
                <img
                  src="/images/pages/EXPERINCE-THE-POWER1.jpg"
                  style={{ width: "100%",}}
                ></img>
              </div></div>
            </div>
              </section>
        {/* Footer */}
        <FooterComponent />
        {/* end: Footer */}
        {/* end: Wrapper */}
        {/* Go to top button */}
        <a href="#home" id="goToTop">
          <i className="fa fa-angle-up top-icon" />
          <i className="fa fa-angle-up" />
        </a>
      </div>
    );
  }
}

export default seromarktest;
