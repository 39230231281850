import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import Nav from "./Nav";
import FooterComponent from "./FooterComponent";

class Totalpsa extends React.Component {
  componentDidMount() {
    window.updateUIAfterReact();
  }
  render() {
    return (
      <div>
        <Nav />
        <div
          className="breadcrumbs"
       >
          {/* Slide 2 */}
          <div className="slide">
            <div className="container">
              <div className="slide-captions text-left text-light">
                {/* Captions */}
                 <h1>Early Detection & Advance Treatment Can Save Lives...</h1>
                {/* end: Captions */}
              </div>
            </div>
          </div>
          {/* end: Slide 2 */}
        </div>
<section className="parallax background-overlay-dark"
        >

          <div className="container">
            <div className="row">
              <div className="col-sm-6">
                <div className="slide-captions text-left">
                  <h2>
                    {" "}
                  Total PSA and free PSA test and its limitations
                  </h2>
                  <p className="pcolor">
                 Whether the patient needs one or both tests, depends on individual situation and determining what a high PSA score means can be complicated. Prostate-specific antigen is not prostate cancer specific and has low sensitivity and specificity for prostate cancer. This is the reason sometimes this question generates much debate and confusion.</p>

 <p className="pcolor">
Prostate cancer can raise PSA levels, but so can other conditions. PSA levels may be increased in conditions like benign prostatic enlargement (BPH), medications given for treatment of enlarged prostate, prostatitis or prostatic inflammation, lower urinary tract infection (UTI), obesity, hepatitis etc. Studies have shown that about 75% of men with an elevated PSA, do not have prostate cancer. Similarly, sometimes PSA is not elevated even if the patient has prostate cancer.</p>


                </div>
              </div>
              <div className="col-sm-6">
                <img
                  src="/images/pages/limitations.jpg"
                  style={{ width: "100%",}}
                 className="ImgTop"></img>
              </div>
            </div>
            <div className="row" style={{margin:"0px",}}>  <p className="pcolor">
For further clarification, some urologists measure free PSA as the proportion of free PSA to total PSA, in cases where PSA level ranges between 4 ng/ml and 10 ng/ml called as “gray zone”.  As per some studies, a total PSA in this range and a free PSA greater than 25% are more likely observed in benign condition than in cancer. Men with a total PSA in the same range and a free PSA below 10% may need to have a biopsy. More likely than not, they have prostate cancer.</p>

 <p className="pcolor">
Increased incidences of prostate cancer in India in recent years and increased willingness of caregivers for treatment, has led to potentially unnecessary biopsies and overtreatment. The SeroMark-1 test with high sensitivity and a negative predictive value of 74% allows for greater confidence to rule out prostate cancer. The SeroMark-1 test is not dependent on PSA.

                  </p>
</div>


          </div>
                  </section>
        {/* Footer */}
        <FooterComponent />
        {/* end: Footer */}
        {/* end: Wrapper */}
        {/* Go to top button */}
        <a href="#home" id="goToTop">
          <i className="fa fa-angle-up top-icon" />
          <i className="fa fa-angle-up" />
        </a>
      </div>
    );
  }
}

export default Totalpsa;
