import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import styles from "./styles.module.css";
import Dropdown from "./Dropdown";
import jwt from "jsonwebtoken";
import AuthService from "./../../auth/AuthService";

let Auth = new AuthService();

export default function DesktopNav() {
  let decoded = jwt.decode(Auth.getToken());
  return Auth.loggedIn() ? (
    <nav className={styles.nav}>
    
     <Link to="/home" className={styles.link}>
        Home
      </Link>

      
        <Dropdown
        title="Caregivers"
        links={[
          { to: "/caregivers/seromarktest-1", tag: "SeroMark-1 Test"},
          {
            to: "/caregivers/psa-test",
            tag: "PSA Test",
          },
          { to: "/caregivers/limitations-of-pas-test", tag: "Limitations of PSA Test" },
          { to: "/caregivers/faqs", tag: "FAQs by Caregivers" },
          { to: "/caregivers/difference-between-screening-and-diagnostic-tests", tag: "Difference between Screening and Diagnostic Tests" },

 { to: "/downloads/clinic-broucher.pdf", tag: "Clinician's Brochure" , target:"_blank" },
        ]}
      />
     <Dropdown
        title="Patients"
        links={[
          { to: "/patients/about-the-prostate", tag: "About the Prostate" },
          {
            to: "/patients/symptoms",
            tag: "Symptoms",
          },
          { to: "/patients/prostate-cancer", tag: "Prostate Cancer" },
           { to: "/patients/screening-tests", tag: "Screening Tests" },
          { to: "/patients/faqs", tag: "FAQs by Patients" },
{ to: "downloads/ask-your-doctor_r3.pdf", tag: "Ask Your Doctor" , target:"_blank" },
{ to: "downloads/patient-broucher.pdf", tag: "Patient's Brochure", target:"_blank"},

        ]}
        />
      <Dropdown
        title="Our Company"
        links={[
          { to: "/company/about", tag: "About Us" },
          { to: "/company/mission", tag: "Mission" },
            { to: "/home#contact", tag: "Location" },
          {
            to: "/company/team", tag: "Our Team",
          },
         
        ]}
        />
       <Dropdown
        title="Publications and Gallery"
        links={[
          { to: "/news", tag: "Blogs/News" },
          { to: "/publications", tag: "Publications" },
          
             { to: "/gallery", tag: "Gallery" },
         
        ]}
      />
       <Link to="/Our-Technology" className={styles.link}>
        Our Technology 
      </Link>
      <Dropdown
        title={decoded.fullName.split(" ")[0]}
        custom={true}
        isLast
        links={[
          // { to: "/changepassword", tag: "Password" },
           { to: "/dashboard", tag: " Tests Status" },
          { to: "/logout", tag: "Logout" },
        ]}
      />
    </nav>
  ) : (
    <nav className={styles.nav}>
 <Link to="/home" className={styles.link}>
        Home
      </Link>

      <Dropdown
        title="Caregivers"
        links={[
          { to: "/caregivers/seromarktest-1", tag: "SeroMark-1 Test" },
          {
            to: "/caregivers/psa-test",
            tag: "PSA Test",
          },
          { to: "/caregivers/limitations-of-pas-test", tag: "Limitations of PSA Test" },
          { to: "/caregivers/faqs", tag: "FAQs by Caregivers" },
          { to: "/caregivers/difference-between-screening-and-diagnostic-tests", tag: "Difference between Screening and Diagnostic Tests" },

 { to: "/downloads/clinic-broucher.pdf", tag: "Clinician's Brochure", target:"_blank", target:"_blank" },

        ]}
      />

      <Dropdown
        title="Patients"
        links={[
          { to: "/patients/about-the-prostate", tag: "About the Prostate",},
          {
            to: "/patients/symptoms",
            tag: "Symptoms",
          },
          { to: "/patients/prostate-cancer", tag: "Prostate Cancer" },
           { to: "/patients/screening-tests", tag: "Screening Tests" },
          { to: "/patients/faqs", tag: "FAQs by Patients" },
{ to: "/downloads/ask-your-doctor_r3.pdf", tag: "Ask Your Doctor",  target:"_blank" },
{ to: "/downloads/patient-broucher.pdf", tag: "Patient's Brochure",  target:"_blank" },


        ]}
        />
      
 <Dropdown
        title="Our Company"
        links={[
          { to: "/company/about", tag: "About Us" },
          { to: "/company/mission", tag: "Mission" },
            { to: "/home#contact", tag: "Location" },
          {
            to: "/company/team", tag: "Our Team"},
         
        ]}
        />

        <Dropdown
        title="Publications and Gallery"
        links={[
          { to: "/news", tag: "Blogs/News" },
          { to: "/publications", tag: "Publications" },
          
             { to: "/gallery", tag: "Gallery"},
         
        ]}
        />
 <Link to="/Our-Technology" className={styles.link}>
        Our Technology 
      </Link>
      <Link to="/login" className={styles.link}>
        Login <i className="fa fa-user"></i>
      </Link>
    </nav>
  );
}
