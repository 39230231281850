import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import Nav from "./Nav";
import FooterComponent from "./FooterComponent";

class AbouttheProstate extends React.Component {
  componentDidMount() {
    window.updateUIAfterReact();
  }
  render() {
    return (
      <div>
        <Nav />
        <div
          className="breadcrumbs"
                 >
          {/* Slide 2 */}
          <div className="slide">
            <div className="container">
              <div className="slide-captions text-left text-light">
                {/* Captions */}
                <h1>The Best Protection is Early Detection!</h1>
                {/* end: Captions */}
              </div>
            </div>
          </div>
          {/* end: Slide 2 */}
        </div>
<section className="parallax background-overlay-dark ">
       <div className="container ">
            <div className="row">
              <div className="col-sm-6">
                <div className="slide-captions text-left ImgTop">
                  <h2>
                    {" "}
                  About the Prostate
                  </h2>
                    <p className="pcolor">
Prostate gland is a little walnut shaped gland, situated in men’s pelvis beneath the bladder. The major function of prostate gland is to produce seminal fluid, the liquid in semen that protects, supports and helps transport sperm.
</p>
                </div>
              </div>
              <div className="col-sm-6">
                <img
                  src="/images/pages/Prostate.png"
                  style={{ width: "100%", float:'right'}}
                ></img>
              </div>
            </div>
          </div>
        </section>
        {/* Footer */}
        <FooterComponent />
        {/* end: Footer */}
        {/* end: Wrapper */}
        {/* Go to top button */}
        <a href="#home" id="goToTop">
          <i className="fa fa-angle-up top-icon" />
          <i className="fa fa-angle-up" />
        </a>
      </div>
    );
  }
}

export default AbouttheProstate;
